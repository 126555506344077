<template>
  <base-button
    class="add-to-cart"
    aria-label="add to cart button"
    :class="{ disabled }"
    :type="type"
    @click.prevent="$emit('add-to-cart')"
  >
    <slot />
  </base-button>
</template>

<script>
import BaseButton from '../BaseButton.vue'

export default {
  name: 'AddToCart',
  components: { BaseButton },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    }
  },
  emits: ['add-to-cart']
}
</script>

<style lang="scss" scoped>
.add-to-cart {
  padding: var(--add-to-cart-padding, 12px);
  width: var(--add-to-cart-width, max-content);
  border-radius: var(--add-to-cart-border-radius, 50%);
  background: var(--c-primary);
  font-weight: var(--add-to-cart-font-weight, normal);
  font-size: var(--add-to-cart-font-size, medium);
  line-height: var(--add-to-cart-line-height, normal);
  letter-spacing: var(--add-to-cart-letter-spacing, normal);
  color: var(--c-white);
  text-transform: uppercase;
  --icon-font-size: 24px;
  --button-padding: 0;
  --button-size: 48px;

  &:hover {
    background: var(--c-dark)
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
    background: var(--c-grey);
  }
}
</style>
