
import Vue, { PropType } from 'vue'
import { AatriumProduct } from '~/modules/catalog/types'
import { useProductAvailability } from '~/modules/catalog/aatrium/product/composables/useProductAvailability'

export default Vue.extend({
  name: 'ProductCardStatuses',
  props: {
    product: {
      type: Object as PropType<AatriumProduct>,
      required: true
    }
  },
  setup (props) {
    const { isProductAvailable, isBackorderAvailable } = useProductAvailability(props.product as AatriumProduct)

    return {
      isBackorderAvailable,
      isProductAvailable
    }
  }
})
