
import { defineComponent, useFetch } from '@nuxtjs/composition-api'
import { PropType } from 'vue'
import { useInstallmentCalculator } from '~/composables/useInstallmentCalculator'
import { safeComputed } from '~/modules/helpers'

export default defineComponent({
  name: 'CheapestMonthlyInstallmentPayment',
  props: {
    price: {
      type: Number as PropType<number>,
      required: true
    }
  },
  setup (props) {
    const { fetch, calculateMinMonthlyPayment } = useInstallmentCalculator()

    useFetch(async () => {
      await fetch()
    })

    const minimalPrice = safeComputed(() => calculateMinMonthlyPayment(props.price))
    return {
      minimalPrice
    }
  }
})
